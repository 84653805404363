import React from "react";
import ReactMarkdown from "markdown-to-jsx";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
const styles = (theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
    textAlign: "left",
  },
});

const options = {
  overrides: {
    h1: {
      component: (props) => (
        <Typography align='left' gutterBottom variant='h4' {...props} />
      ),
    },
    h2: {
      component: (props) => (
        <Typography align='left' gutterBottom variant='h6' {...props} />
      ),
    },
    h3: {
      component: (props) => (
        <Typography align='left' gutterBottom variant='subtitle1' {...props} />
      ),
    },
    h4: {
      component: (props) => (
        <Typography
          align='left'
          gutterBottom
          variant='caption'
          paragraph
          {...props}
        />
      ),
    },

    b: {
      component: (props) => (
        <Typography fontWeight='fontWeightBold' {...props} />
      ),
    },
    p: {
      component: (props) => <Typography align='left' paragraph {...props} />,
    },
    span: {
      component: (props) => <Typography align='left' paragraph {...props} />,
    },
    a: {
      component: (props) => {
        if (props.href.includes(window.navigator.origin)) {
          props.href = props.href.replace(window.navigator.origin, "");
          return <Link component={RouterLink} to={props.href} {...props} />;
        } else {
          return <Link {...props} />;
        }
      },
    },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography align='left' component='span' {...props} />
        </li>
      )),
    },
  },
};

function Markdown(props) {
  return <ReactMarkdown options={options} {...props}></ReactMarkdown>;
}

export default Markdown;
