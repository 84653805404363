import React, { Suspense, lazy, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  MemoryRouter,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
// import RouterBreadcrumbs from "./Navigation/BreadCrumbs";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import { ENTRANT_ORG } from "./API/Queries/EntrantOrgQuery";
import { useQuery } from "@apollo/client";
import useGlobalState from "./GlobalState/GlobalState";
import { Loading } from "./Components/View/Loading";
const FullHome = lazy(() => import("./views/Home/FullHome"));
const About = lazy(() => import("./views/About/About"));
const User = lazy(() => import("./views/User/User"));
const Events = lazy(() => import("./views/Events/Events"));
// const Competition = lazy(() => import("./views/Competition/Competition"));
const Results = lazy(() => import("./views/Results/Results"));
const PasswordRecovery = lazy(() =>
  import("./views/PasswordRecovery/PasswordRecovery")
);
const Event = lazy(() => import("./views/Event/Event"));
const Article = lazy(() => import("./views/Article/Article"));
const Announcements = lazy(() => import("./views/Announcements/Announcements"));
const Announcement = lazy(() => import("./views/Announcements/Announcement"));
const SupportRouter = lazy(() => import("./views/Support/SupportRouter"));
const Sponsors = lazy(() => import("./views/Sponsors/Sponsors"));
const Login = lazy(() => import("./views/Login/Login"));
const Register = lazy(() => import("./views/Register/Register"));
const Nation = lazy(() => import("./views/Nation/Nation"));
const Contact = lazy(() => import("./views/Contact/Contact"));
const Team = lazy(() => import("./views/Team/Team"));
const Organization = lazy(() => import("./views/Organization/Organization"));
const Staff = lazy(() => import("./views/Staff/Staff"));
// const League = lazy(() => import("./views/League/League"));
const Logout = lazy(() => import("./Components/Auth/Logout"));
const AthletesTeams = lazy(() => import("./views/AthletesTeams/AthletesTeams"));
const Template = lazy(() => import("./views/Template/Template"));
const Four0Four = lazy(() => import("./views/Four0Four/Four0Four"));
// const Round = lazy(() => import("./views/Round/Round"));
const Result = lazy(() => import("./views/Result/Result"));
const Manager = lazy(() => import("./views/Manager/Manager"));
const Onboarding = lazy(() => import("./views/User/Onboarding/Onboarding"));
const DataPass = lazy(() => import("./views/DataPass/DataPass"));
const Scorecard = lazy(() => import("./views/Scorecard/Scorecard"));
const Venue = lazy(() => import("./views/Venue/Venue"));
const Scan = lazy(() => import("./views/Scan/Scan"));
const Test = lazy(() => import("./views/Tests/TestsRouter"));

export default function Routes() {
  return (
    <Fragment>
      {/* <RouterBreadcrumbs /> */}
      <ScrollToTop />
      <Suspense fallback={<></>}>
        <Switch>
          {process.env.NODE_ENV === "development" ? (
            <Route exact path='/' component={FullHome} />
          ) : (
            <Route exact path='/' component={FullHome} />
          )}
          //Auth
          <Route path={"/login"} component={Login} />
          <Route path={"/register"} component={Register} />
          <Route path={"/logout"} component={Logout} />
          <Route path={"/about"} component={About} />
          <Route path={"/support"} component={SupportRouter} />
          <Route path={"/events"} component={Events} />
          <Route path={"/sponsors"} component={Sponsors} />
          <Route path={"/athletes-teams"} component={AthletesTeams} />
          <Route path='/result/:id' component={Result} />
          <Route path={"/results"} component={Results} />
          <Route exact path={"/recover"} component={PasswordRecovery} />
          <Route path={"/recover/:token"} component={PasswordRecovery} />
          <Route path={"/announcement/:id"} component={Announcement} />
          <Route path={"/announcements"} component={Announcements} />
          <Route path={"/contact"} component={Contact} />
          <Route path={"/manager"} component={Manager} />
          <Route path={"/datapass"} component={DataPass} />
          <Route path={"/scan"} component={Scan} />
          <Route path={"/test"} component={Test} />
          <Route path={"/disciplines"} />
          <Route path={"/lead"} />
          <Route path={"/speed"} />
          <Route path={"/boulder"} />
          <Route path='/staff' component={Staff} />
          <Route path='/rules' component={Template} />
          <Route path='/training' component={Template} />
          <Route path='/template' component={Template} />
          <Route path='/a/:id' component={Article} />
          <Route path='/e/:id' component={Event} />
          <Route path='/c/:id' component={Event} />
          <Route path='/l/:id' component={Event} />
          <Route path='/n/:ioc_code' component={Nation} />
          <Route path='/o/:username' component={Organization} />
          <Route path='/r/:id' component={Event} />
          <Route path='/s/:id' component={Scorecard}></Route>
          <Route path='/t/:id' component={Team} />
          <Route path='/v/:id' component={Venue} />
          <Route
            exact
            path='/:username/complete-profile'
            component={Onboarding}
          />
          <Route path='/404' component={Four0Four} />
          {/* Allow users to search for any username from the address bar  */}
          <Route path='/:username' component={Username}></Route>
        </Switch>
      </Suspense>
    </Fragment>
  );
}

function Username(props) {
  const { loading, error, data, refetch } = useQuery(ENTRANT_ORG, {
    variables: { username: props.match.params.username },
  });
  if (loading) return <Loading />;
  if (error) return <Redirect to='/404' />;
  if (data) {
    if (!data.entrant_org) return <Redirect to='/404' />;
    if (data.entrant_org.__typename === "User") {
      return (
        <User refetch={refetch} loading={loading} user={data.entrant_org} />
      );
    } else if (data.entrant_org.__typename === "Organization") {
      return (
        <Organization
          refetch={refetch}
          loading={loading}
          organization={data.entrant_org}
        />
      );
    }
  }
  return null;
}
