import { gql } from "@apollo/client";

const entrant_query = `
id
username
name
sports {
  name
}
sponsoredBy {
  id
  name
  username
  photo
}
scorecards {
  id
  resultRank
  verified
  selfScored
  updatedAt
  class {
    name
  }

  parent {
    id
    name
    endDate
    ... on Competition {
      league {
        id
        name
      }
    }
    ... on Round {
      competition {
        id
        name
        league {
          id
          name
        }
      }
    }
  }
}
social_links {
  platform
  handle
  url
  text
}
`;

const user_query = `
firstName
lastName
picture
verified
age
hometown
isMe
nation {
  id
  IOC
  ISO
}

`;
// ${entrant_query}
//  ${user_query}

const organization_query = ` 
id
      name
      username
      description
      photo
      isAdmin
      social_links{
        platform
        text
        handle
        url
      }
      entrants {
        ... on User {
          id
          name
          firstName
          lastName
          username
          picture
          nation {
            IOC
            ISO
          }
        }
      }
      venues {
        id
        name
        photo
        owners{
          photo
        }
        address {
          city
          country
        }
      }
      sponsoredVenues{
        id
        name
        photo
        owners{
          photo
        }
        address {
          city
          country
        }
      }
      admins {
        id
        name
        username
      }
      staff {
        id
        name
        firstName
        lastName
        username
        picture
      }
`;

export const ENTRANT_ORG = gql`
  query EntrantOrg($username: String!) {
    entrant_org(username: $username) {
      __typename
      ...on User{
        ${entrant_query}
        ${user_query}
      }
      ...on Organization{
        ${organization_query}
      }
    }
  }
`;
