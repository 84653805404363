import React from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Grid, Link, Tooltip, Box } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";

export default function SocialLinks({
  color,
  social_links,
  gridClass,
  justify,
}) {
  return (
    <Box mt={2} mb={2}>
      <Grid spacing={2} className={gridClass} justify={justify} container>
        {social_links
          ? social_links.map((link) => (
              <Grid key={link.platform} item>
                <SocialTile color={color} {...link} />
              </Grid>
            ))
          : null}
      </Grid>
    </Box>
  );
}

function SocialTile({ color, platform, handle, text, url }) {
  let Icon;
  let Handle = handle;
  let to = "/";
  if (platform === "INSTAGRAM") {
    Icon = <InstagramIcon />;
    Handle = `@${Handle}`;
    to = url || `https://instagram.com/${handle}`;
  } else if (platform === "TWITTER") {
    Icon = <TwitterIcon />;
    Handle = `@${Handle}`;
    to = url || `https://twitter.com/${handle}`;
  } else if (platform === "YOUTUBE") {
    Icon = <YouTubeIcon />;
    to = url || `https://www.youtube.com/user/${handle}`;
  } else if (platform === "FACEBOOK") {
    Icon = <FacebookIcon />;
    to = url || `https://www.facebook.com/${handle}`;
  } else if (platform === "WEBSITE") {
    Icon = <LanguageIcon />;
    to = url;
  }
  if (!to) return null;
  if (!handle && !url) return null;
  return (
    <Tooltip title={Handle || text || url}>
      <Link color={color ? color : "textSecondary"} href={to}>
        {Icon}
      </Link>
    </Tooltip>
  );
}
