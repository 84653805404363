import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Footer from "./Navigation/Footer.jsx";
import AppBar from "./Navigation/AppBar.jsx";
import Routes from "./Router";
import { MuiThemeProvider } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import theme from "./theme";
// Apollo Client
// V. 3
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { split, from } from "apollo-link";
import jwtDecode from "jwt-decode";
import { onError } from "apollo-link-error";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";

import { getAccessToken, setAccessToken } from "./AccessToken";

import TelikoSnackbar from "./Notifications/TelikoSnackbar";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import useGlobalState from "./GlobalState/GlobalState";
import { Loading } from "./Components/View/Loading";

global.bucket = "https://storage.clod.google.com/teliko-1/";

export const API_ORIGIN =
  process.env.NODE_ENV === "development"
    ? "://localhost:4000"
    : //  ? "://localhost:4000"
      "s://teliko-server.herokuapp.com";

// Create an http link:
const httpLink = new HttpLink({
  uri: `http${API_ORIGIN}/api`,
  credentials: "include",
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: `ws${API_ORIGIN}/graphql`,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: `Bearer ${getAccessToken()}`,
    },
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const asyncAuthLink = setContext(async (request) => {
  let token = getAccessToken();

  if (!token) {
    token = await getNewToken();
  } else {
    try {
      const { exp } = jwtDecode(token);
      if (Date.now() >= exp * 1000) {
        token = await getNewToken();
      }
    } catch (err) {
      console.warn(err);
    }
  }
  return { headers: { authorization: token ? `bearer ${token}` : "" } };
});

async function getNewToken() {
  let data = await fetch(`http${API_ORIGIN}/refresh_token`, {
    method: "POST",
    credentials: "include",
  }).then((x) => x.json());

  if (data.user) {
    setAccessToken(data.user.token);
    return data.user.token;
  } else {
    return undefined;
  }
}

const networkLink = from([
  asyncAuthLink,
  onError(({ graphQLErrors, networkErrors }) => {
    console.log({ graphQLErrors });
    console.log({ networkErrors });
  }),
  // requestLink,
  link,
]);

export const client = new ApolloClient({
  link: networkLink,
  credentials: "include",
  cache: new InMemoryCache({}),
});

function App(props) {
  const [loading, setloading] = useState(true);
  const [globalUser, setGlobalUser] = useGlobalState("user");
  useEffect(() => {
    let headers = new Headers();
    headers.append("Content-type", "application/json");
    headers.append("Accept", "application/json");

    fetch(`http${API_ORIGIN}/refresh_token`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
    }).then(async (x) => {
      const data = await x.json();
      // console.log(data);
      if (data && data.user) {
        setGlobalUser(data.user);
        setAccessToken(data.user.token);
      } else {
        setloading(false);
      }
    });
    setTimeout(() => {
      setloading(false);
    }, 10000);
  }, []);

  useEffect(() => {
    if (globalUser) setloading(false);
  }, [globalUser]);

  return (
    <MuiThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {loading ? (
            <Loading />
          ) : (
            <div className='App'>
              <BrowserRouter>
                <AppBar mainContent={<Routes />} footer={<Footer />} />
                <TelikoSnackbar />
              </BrowserRouter>
            </div>
          )}
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </MuiThemeProvider>
  );
}

export default App;
