import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import useGlobalState from "../GlobalState/GlobalState";
import logo from "../Assets/logos/narrow/GreyOnDark512.png";
import { PoweredByTeliko } from "../Components/Teliko/TelikoBadges";
import SocialLinks from "../Components/Social/SocialLinks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // minHeight: "100vh"
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  copyright: { marginTop: theme.spacing(3) },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    // marginTop: "auto",
  },
  footerText: {
    color: theme.palette.primary.contrastText,
  },
  paper: {
    background: "blue",
  },
  teliko: {
    // marginBottom: theme.spacing(3),
    margin: theme.spacing(3, 0),
  },
}));

function Copyright() {
  const classes = useStyles();

  const [name] = useGlobalState("name");
  return (
    <Typography className={classes.copyright} variant='body2' align='center'>
      {"Copyright © "}
      <Link color='inherit' href='/'>
        {name}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function StickyFooter() {
  const classes = useStyles();
  const [footerVisible] = useGlobalState("footerVisible");

  return (
    <>
      {footerVisible ? (
        <div className={classes.root}>
          {/* <CssBaseline /> */}
          <Container className={classes.footer} maxWidth='lg'>
            <SecondaryLinks />
          </Container>
          <Grid justify='center' container>
            <Grid item>
              <SocialLinks
                color='inherit'
                social_links={[
                  { platform: "INSTAGRAM", handle: "british_climbing_cup" },
                  {
                    platform: "FACEBOOK",
                    handle: "British Climbing Cup",
                    url:
                      "https://www.facebook.com/British-Climbing-Cup-106029674411603",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Copyright />
          <div className={classes.teliko}>
            <PoweredByTeliko />
          </div>
        </div>
      ) : null}
    </>
  );
}

function SecondaryLinks() {
  const classes = useStyles();

  const [name] = useGlobalState("name");
  return (
    <Grid justify='center' spacing={2} container>
      {[
        { primary: "Home", to: "/" },
        // { primary: "Staff Area", to: "/staff" },
        { primary: "Privacy", to: "/support/privacypolicy" },
        { primary: "Terms & Conditions", to: "/support/terms-conditions" },
        { primary: "Cookies", to: "/support/cookie-policy" },
        { primary: "Contact", to: "/contact" },
      ].map((link, i, arr) => {
        return (
          <Grid key={link.primary} xs={4} sm={1} item>
            <Typography gutterBottom variant='body2' align='center'>
              <Link
                component={RouterLink}
                className={classes.footerText}
                to={link.to}>
                {link.primary}
              </Link>
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}
