import React, { useEffect } from "react";
import useGlobalState from "../../GlobalState/GlobalState";

import { Breadcrumbs, Container, Paper, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Loading } from "./Loading";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  noPadding: {
    marginTop: theme.spacing(5),
  },
  loading: {
    zIndex: 999999999,
    position: "absolute",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    textAlign: "center",
    backgroundImage: `linear-gradient(326deg, ${theme.palette.secondary.light} 0%, ${theme.palette.primary.dark} 94%)`,
    background: theme.palette.primary.light,
  },
  content: { marginTop: theme.spacing(4) },
  spinner: { height: "100%", color: "white" },
  box: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
}));

export default function View(props) {
  const {
    children,
    pageTitle,
    appBarTitle,
    noMargin,
    noPadding,
    noGutters,
    loading,
    error,
  } = props;
  const classes = useStyles();

  const [_p, setPageTitle] = useGlobalState("pageTitle");
  const [name] = useGlobalState("name");

  const setGlobalPageTitle = () => {
    if (!loading) {
      document.title = pageTitle !== name ? `${pageTitle} - ${name}` : name;
      setPageTitle(appBarTitle || pageTitle);
    }
  };

  useEffect(() => {
    setGlobalPageTitle();
    return () => {
      // setPageTitle("");
    };
  }, [pageTitle]);

  useEffect(() => {
    setGlobalPageTitle();
    return () => {};
  }, []);

  return (
    <>
      {loading ? <Loading /> : null}

      {(error || children) && (
        <Container
          className={noPadding ? classes.root : classes.content}
          maxWidth={noGutters || noPadding ? "xl" : props.maxWidth}
          disableGutters={noGutters || noPadding}>
          {error ? <div>{error.message}</div> : children}
        </Container>
      )}
    </>
  );
}
