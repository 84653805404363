import React, { useEffect, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  MenuList,
  MenuItem,
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  SvgIcon,
} from "@material-ui/core";
import { Detector } from "react-detect-offline";
import Fade from "@material-ui/core/Fade";

// icons
import HomeIcon from "@material-ui/icons/Home";
import EventIcon from "@material-ui/icons/Event";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import StarIcon from "@material-ui/icons/Star";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import OfflineBolt from "@material-ui/icons/OfflineBolt";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PeopleIcon from "@material-ui/icons/People";
import MailIcon from "@material-ui/icons/Mail";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import useGlobalState from "../GlobalState/GlobalState";
import GavelIcon from "@material-ui/icons/Gavel";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import logo from "../Assets/logos/ColorOnLight192.png";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import { AvatarGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    account: {
      marginBottom: theme.spacing(1),
    },
    offline: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    online: {
      display: "none",
    },
    list: { paddingTop: "0px" },
    smallAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
);

export default function Drawer({
  handleDrawerOpen,
  handleDrawerClose,
  drawerOpen,
}) {
  const [user] = useGlobalState("user");
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const userOptions = !user
    ? []
    : [
        {
          primary: "Scan",
          icon: <CenterFocusWeakIcon />,
          to: `/scan`,
        },
        // {
        //   primary: "Tickets",
        //   icon: <ConfirmationNumberIcon />,
        //   disabled: true,
        //   to: `/tickets`,
        // },
        {
          primary: "Manage Athletes",
          disabled: true,
          icon: (
            <AvatarGroup max={2}>
              <Avatar className={classes.smallAvatar} alt='1' />
              <Avatar className={classes.smallAvatar} alt='2' />
            </AvatarGroup>
          ),
          to: `/manager`,
        },
      ];

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <MenuList className={classes.list}>
        <Link component={RouterLink} to='/'>
          <img src={logo} />
        </Link>
        <Detector
          render={({ online }) => (
            <Fade in={!online}>
              <MenuItem className={!online ? classes.offline : classes.online}>
                {!online ? (
                  <>
                    <ListItemIcon>{<OfflineBolt />}</ListItemIcon>
                    <ListItemText primary={"Offline"} />
                  </>
                ) : null}
              </MenuItem>
            </Fade>
          )}
        />
        {[
          {
            options: { className: classes.account },
            primary: user ? `${user.firstName} ${user.lastName}` : "Sign In",
            to: user ? `/${user.username}` : "/login",
            open: true,
            icon: (
              <Avatar
                src={user ? user.picture : ""}
                alt={
                  user ? `${user.firstName} ${user.lastName}` : null
                }></Avatar>
            ),
          },
          ...userOptions,
          { primary: "divider" },
          { to: "/", primary: "Home", icon: <HomeIcon /> },
          {
            to: "/events",
            primary: "Events",
            icon: <EventIcon />,
          },
          // {
          //   to: "/results",
          //   disabled: true,
          //   primary: "Results",
          //   icon: <EmojiEvents />,
          // },
          {
            to: "/athletes-teams",
            primary: "Athletes & Teams",
            icon: <PeopleIcon />,
          },
          {
            to: "/sponsors",
            disabled: true,
            primary: "Sponsors",
            icon: <StarIcon />,
          },
          {
            to: "/rules",
            disabled: true,
            primary: "Rulebook",
            icon: <GavelIcon />,
            nested: [
              {
                to: "/rules/boulder",
                disabled: true,
                primary: "Boulder",
              },
              // {
              //   to: "/rules/speed",
              //   disabled: true,
              //   primary: "Speed",
              // },
              // {
              //   to: "/rules/lead",
              //   disabled: true,
              //   primary: "Lead",
              // },
            ],
          },
          // {
          //   to: "/training",
          //   disabled: true,
          //   primary: "Training Resources",
          //   icon: <FitnessCenterIcon />,
          // },
          // {
          //   to: "/announcements",
          //   primary: "Announcements",
          //   disabled: true,
          //   icon: <AnnouncementIcon />,
          // },
          { primary: "divider" },
          {
            to: "/datapass",
            disabled: true,
            primary: "DataPass",
            icon: <ReceiptIcon />,
          },
          { to: "/contact", primary: "Contact", icon: <MailIcon /> },
        ].map(({ nested, open, primary, disabled, options, to, icon }, i) => {
          if (primary === "divider") {
            return <Divider key={`${primary}-${i}`} />;
          } else {
            return (
              <DrawerItem
                nested={nested}
                key={primary}
                disabled={disabled}
                options={options}
                primary={primary}
                icon={icon}
                to={to}
                defaultOpenState={open}
              />
            );
          }
        })}
      </MenuList>
    </>
  );

  function DrawerItem({
    nested,
    disabled,
    defaultOpenState,
    primary,
    to,
    icon,
    options = {},
  }) {
    const [open, setOpen] = React.useState(defaultOpenState);
    const handleClick = () => {
      setOpen(!open);
      if (!nested) {
        if (drawerOpen) handleDrawerClose();
      }
    };
    return (
      <>
        <ListItem
          button
          disabled={disabled}
          onClick={handleClick}
          {...options}
          component={nested ? ListItem : RouterLink}
          to={nested ? null : to}>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={primary} />
          {nested ? open ? <ExpandLess /> : <ExpandMore /> : null}
        </ListItem>

        {nested ? (
          <Collapse in={open} timeout='auto' unmountOnExit>
            <MenuList component='div' disablePadding>
              {nested.map((nestedItem) => {
                return <NestedDrawerItem key={nestedItem.to} {...nestedItem} />;
              })}
            </MenuList>
          </Collapse>
        ) : null}
      </>
    );
  }

  function NestedDrawerItem({ disabled, primary, to, icon }) {
    const handleClick = () => {
      if (drawerOpen) handleDrawerClose();
    };
    return (
      <ListItem
        dense
        component={RouterLink}
        to={to}
        disabled={disabled}
        button
        onClick={handleClick}
        key={primary}
        className={classes.nested}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={primary} />
      </ListItem>
    );
  }
}
