import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { Alert, AlertTitle } from "@material-ui/lab";
import Markdown from "../Components/Markdown/Markdown";
import BugReportIcon from "@material-ui/icons/BugReport";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
export function OpenBeta() {
  return (
    <Box mb={5}>
      <Container style={{ textAlign: "left" }} maxWidth='md'>
        <Alert icon={<BugReportIcon />} variant='outlined' severity='info'>
          <AlertTitle>Open Beta</AlertTitle>
          <Markdown>{`The British Climbing Cup site is in open beta. If you come across
        anything that doesn't work as expected [please send us a message](/contact) and we'll get it fixed.`}</Markdown>
          <Button
            endIcon={<BugReportIcon />}
            color='inherit'
            component={Link}
            to='/contact'>
            Report Bug
          </Button>
        </Alert>
      </Container>
    </Box>
  );
}
