import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useGlobalState from "../GlobalState/GlobalState";

export default function TelikoSnackbar(props) {
  const [Init, setInit] = useState(false);
  const [T, setT] = useState("text");
  const [S, setS] = useState("info");
  const [open, setOpen] = useState(false);
  const [snackbar, setsnackbar] = useGlobalState("snackbar");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (Init) {
      if (open) {
        setOpen(false);
      }
      setT(snackbar.text);
      setS(snackbar.severity || "info");
      setOpen(true);
    }

    return () => {};
  }, [snackbar]);

  useEffect(() => {
    setInit(true);
    return () => {};
  }, []);

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={S}>
        {T}
      </Alert>
    </Snackbar>
  );
}
