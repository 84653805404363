import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  teliko: {
    fontFamily: "'Syncopate', sans-serif;"
  }
}));

export function PoweredByTeliko() {
  const classes = useStyles();

  return (
    <>
      <Typography gutterBottom variant='body1'>
        powered by
      </Typography>
      <Typography className={classes.teliko} variant='body1'>
        TELÍKO
      </Typography>
    </>
  );
}

export function TelikoWillAsk() {
  const classes = useStyles();

  return (
    <>
      <Typography
        color='text.disabled'
        className={classes.teliko}
        variant='body1'>
        TELÍKO
      </Typography>
      <Typography component='h1' variant='body1'>
        will ask for permission.
      </Typography>
    </>
  );
}
