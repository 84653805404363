import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Box, Typography } from "@material-ui/core";
import { useStyles } from "./View";
import useGlobalState from "../../GlobalState/GlobalState";

export function Loading() {
  const classes = useStyles();
  const [shortname] = useGlobalState("short_name");
  return (
    <div className={classes.root}>
      <Grid
        className={classes.loading}
        direction='row'
        justify='center'
        alignItems='stretch'
        container>
        <Grid className={classes.spinner} item>
          <Box className={classes.box} color='white'>
            <Grid spacing={2} direction='column' container>
              <Grid item>
                <CircularProgress color='inherit' />
              </Grid>
              <Grid item>
                <Typography
                  fontWeight='fontWeightBold'
                  style={{ color: "white" }}
                  variant='h4'>
                  BRITISH CLIMBING CUP
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
