import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DrawerList from "./Drawer";
import useGlobalState from "../GlobalState/GlobalState";
import { Button, Avatar, ClickAwayListener, Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { OpenBeta } from "./OpenBeta";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  footer: {
    // position: "fixed",
    // bottom: 0,
    // minHeight: "100vh",
    marginTop: "30vh",
    display: "flex",
    flexDirection: "column-reverse",
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  footerShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: drawerWidth,
    // transition: theme.transitions.create(["margin", "width"], {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    paddingBottom: "10vw",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: drawerWidth,
  },
}));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  const [pageTitle] = useGlobalState("pageTitle");
  const [user] = useGlobalState("user");
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, open && classes.hide)}>
            <MenuIcon />
          </IconButton>

          <Typography
            className={classes.title}
            align='left'
            variant='h6'
            noWrap>
            {pageTitle}
          </Typography>

          {user ? (
            <>
              <IconButton component={RouterLink} to={"/scan"} color='inherit'>
                <CenterFocusWeakIcon />
              </IconButton>
              <Avatar
                component={RouterLink}
                to={`/${user.username}`}
                src={user.picture}
              />
            </>
          ) : (
            <Grid style={{ width: "auto" }} container spacing={2}>
              <Grid item>
                <Button
                  component={RouterLink}
                  color={xs ? undefined : "inherit"}
                  variant={xs ? "contained" : undefined}
                  to='/login'>
                  Login
                </Button>
              </Grid>
              {!xs && (
                <Grid item>
                  <Button
                    component={RouterLink}
                    to='/register'
                    color='secondary'
                    variant='contained'>
                    Register
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <ClickAwayListener
        onClickAway={() => {
          if (open) handleDrawerClose();
        }}>
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />

          <DrawerList
            drawerOpen={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
          />
        </Drawer>
      </ClickAwayListener>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}>
        <div className={classes.drawerHeader} />
        <div>{props.mainContent}</div>
      </main>
      <div
        className={clsx(classes.footer, {
          [classes.footerShift]: open,
        })}>
        <div>{props.footer}</div>
        <OpenBeta />
      </div>
    </div>
  );
}
