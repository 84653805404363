import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  teliko_app_id: "5e7b58fc5ea7093fd0f496ca",
  short_name: "BCC",
  name: "British Climbing Cup",
  pageTitle: "British Climbing Cup",
  subtitle: "The UK's National Climbing League.",
  footerVisible: true,
  user: undefined,
  logos: {
    main: require("../Assets/logos/narrow/ColorOnLight192.png"),
    main_large: require("../Assets/logos/narrow/ColorOnLight512.png"),
  },
  social: {
    instagram: {
      handle: "@british_climbing_cup",
      url: "http://instagram.com/british_climbing_cup",
    },
  },
  snackbar: { text: "", severity: "info" },
};

const { useGlobalState } = createGlobalState(initialState);

export default useGlobalState;
